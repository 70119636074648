import {
  FORM_TYPE_DEFAULT,
  FORM_TYPE_LAND,
  FORM_TYPE_PROPERTY,
} from "../../constants/common";

export default {
  PRICING_RULE_LIST: "Liste des tarif",
  ADD_PRICING_RULES: "Créer un tarif",
  PRICING_RULE_ADDED: "Tarif ajouté",
  EDIT_PRICING_RULE: "Modifier le tarif",
  PRICING_RULES_UPDATED: "Prix mis à jour",
  DETAILS_PRICING_RULE: "Détails du Tarif",
  DELETE_THIS_PRICING_RULE: "Voulez-vous supprimer le tarif sélectionné ?",
  DELETE_THIS_PRICING_RULE_SUCCESS:
    "La suppression de le tarif a été effectuée avec succès",
  PRICING_RULE_DELETED: "Catégorie supprimée",
  SPOT_FORM_TYPE: "Type de formulaire",
  SPOT_FORM_TYPE_DEFAULT: "Par défaut",
  SPOT_FORM_TYPE_LAND: "Terrain",
  SPOT_FORM_TYPE_PROPERTY: "Propriété",
  VIEW_PRICING_RULE: "tarif",
  SAME_SPOT_CATEGORIES:
    'Un ou plusieurs catégories d\'emplacements existent déja avec le nom {name}. S\'agit-il de la même catégory ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA:
    "Vous n’avez ajouté aucune tarif pour le moment, cliquer sur le bouton pour ajouter.",
  PRICING_RULE_INFORMATION: "Informations sur le tarif",
  PRICING_RULE_NAME: "Nom du tarif",
  PRICING_RULE_PRICING_TYPE: "Type de tarif",
  PRICING_RULE_VALUE: "Prix / Pourçentage",
  PRICE: "Tarif",
  CALENDAR: "Calendrier",
  DERIVED: "Dérivé de",
  END_OF_WEEK: "Fin de semaine",
  VARIATION_PLUS_OR_MINUS: "Variation Plus + ou Moins -",
  EMPTY_VARIATION_PLUS_OR_MINUS: "Aucune variation Plus + ou Moins -",
  VARIATION_IN_PRICE_OR_PERCENT: "Variation en Prix ou en Pourcentage",
  START_END_DATE_SEASON: "Date de début et de fin de la saison",
  START_DATE: "Date de début",
  END_DATE: "Date de fin",
  IS_SEASONAL: "Est saisonnier",
  PER_PERSON: "Par personne",
  PRICES: "Tarifs",
  PASTE_PRICES: "Coller les tarifs",
  COSTS: "Frais",
  RESTRICTIONS: "Restrictions",
  MARKEING: "Marketing et étiquettes",
  WEB: "Web",
  COPY_ALL_FIELDS: "Copier tous les champs",
  BUNDLE: "Forfait",
  LODGING_TAX: "Taxe d'hébergement",
  ADULTADDCOST: "Frais additionnel adulte",
  CHILDRENADDCOST: "Frais additionnel enfants",
  MINIMUM_STAY: "Séjour minimum",
  MAXIMUM_STAY: "Séjour maximum",
  MINIMUM_NIGHT: "Nombre de nuits minimum",
  MAXIMUM_NIGHT: "Nombre de nuits maximum",
  MINIMUM_DAY_BEFORE_ARRIVAL: "Minimum de jour avant l'arrivée",
  MAXIMUM_DAY_BEFORE_ARRIVAL: "Maximum de jour avant l'arrivée",
  MINIMUM_PERSON: "Nombre de personnes minimum",
  MAXIMUM_PERSON: "Nombre de personnes maximum",
  ACTIVE_FROM: "Actif à partir du",
  ACTIVE_UNTIL: "Actif jusqu'au",
  SOURCE: "Source",
  CANAL: "Canal",
  SEGMENT: "Segment",
  IDENTIFICATION: "Identification",
  PROMO_CODE: "Code promo",
  PUBLISH_ONLINE_RESERVATION: "Publier dans le moteur de réservations en ligne",
  BADGE: "Badge",
  DISCOUNT_RATES_DISPLAY: "Affichage du % de réduction",
  ADD_ID: "Ajouter ID",
  CREATE: "Créer",
  MAX_CARACTERS: "(120 caractères max)",
  EMPTY_DESCRIPTION: "Aucune Description",
  SELECT_A_TIME_INTERVAL: "Selectionner un interval de temps",
  PRICING_RULE_PASTED: "Les informations ont été collées dans pricingRule avec succès !",
  PRICING_RULE_COPIED: "Les informations de la règle de tarification ont été copiées avec succès !",
  COPY_FAILURE: "Échec de la copie des informations.",
  INVALID_PASTE_FORMAT: "Échec du collage des informations. Format non valide.",
  ERROR_FETCHING_DATA_IN_CLIPBOARD: "Échec de la récupération des informations depuis le presse-papiers."

};
