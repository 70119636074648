export default {
  SALES_DELIVERIES_LIST: "Liste des livraisons",
  ADD_SALES_DELIVERY: "Ajouter une livraison",
  ADD_SALES_DELIVERY_ITEM: "Ajouter une ligne",
  SALES_DELIVERY_ADDED: "Livraison ajoutée",
  OWNER: "Propriétaire",
  EDIT_SALES_DELIVERY: "Modifier une livraison",
  SALES_DELIVERY_UPDATED: "Livraison mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_SALES_DELIVERY_ITEM: "Supprimer la ligne",
  UPDATE_SALES_DELIVERY_ITEM: "Modifier la ligne",
  SALES_DELIVERY_ITEM_DELETED: "Ligne supprimée",
  SALES_DELIVERY_ITEM_ADDED: "Ligne ajoutée",
  SALES_DELIVERY_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  SALES_DELIVERY_SENT: "Livraison envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir le camping",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_SALES_DELIVERY: "Supprimer cette livraison ?",
  SALES_DELIVERY_DELETED: "Livraison supprimée",
  CANCEL_SALES_DELIVERY_QUESTION: "Annuler la livraison ?",
  SALES_DELIVERY_CANCELED: "Livraison annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  SALES_DELIVERY_VALIDATED: "Livraison validée",
  VIEW_ORDER: "Voir la commande",
  VIEW_SALES_DELIVERY: "Livraison vente",
  VALIDATE_DELIVERY: "Valider la livraison",
  SALES_ORDER_DELIVERY_ADDED: "Livraison ajoutée",
};
