export default {
  OPTIONS_LIST: "Liste des paramètres",

  MODULE: "Module",
  NAME: "Nom",
  VALUE: "Valeur",
  MODULE_GENERAL: "Général",
  MODULE_PURCHASES: "Achats",
  MODULE_SALES: "Ventes",
  OPTION_DOCUMENT_FOOTER: "Pied de page des document",
  MODULE_PUBLIC_WEBSITE: "Site web publique",

  OPTION_DEFAULT_TAXES_GROUP: "Groupe de taxes par défaut",
  OPTION_DEFAULT_PUBLIC_WEBSITE_LOGO: "Logo",
  OPTION_DEFAULT_PUBLIC_WEBSITE_COVER_IMAGE: "Image de couverture",
  OPTION_DEFAULT_PUBLIC_WEBSITE_HOME_PAGE_DESIGN: "Design de la page d’accueil",
  OPTION_DEFAULT_PUBLIC_WEBSITE_ORGANIZATION_HOME_PAGE_URL:
    "Lien de la Page d’accueil du camping",

  HOME_PAGE_1: "Page d’accueil 1",
  HOME_PAGE_2: "Page d’accueil 2",
};
