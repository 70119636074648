export default {
  FILES_LIST: "Liste des fichiers",
  ADD_FILE: "Ajouter un fichier",
  ADD_FILES: "Ajouter des fichiers",
  FILE_ADDED: "Fichier ajouté",
  EDIT_FILE: "Modifier un fichier",
  FILE_UPDATED: "Fichier mis à jour",
  DELETE_FILE: "Supprimer le fichier",
  DELETE_THIS_FILE: "Supprimer ce fichier ?",
  FILE_DELETED: "Fichier supprimé",
  FILE_TYPE: "Type de fichier",
  CHOOSE_FILE: "Choisir un fichier",
  SIZE: "Taille",
  DOWNLOAD: "Télécharger",
  OBJECT: "Objet lié",
  ALL_OBJECTS: "Tous les objets liés",
  NEW_FOLDER: "Nouveau dossier",
  NEW_FILE: "Nouveau fichier",
  COPY: "Copier",
  MOVE: "Déplacer",
  REMOVE: "Supprimer",
  ADD_FOLDER: "Ajouter un dossier",
  FOLDER_ADDED: "Dossier ajouté",
  FOLDER_UPDATED: "Dossier modifié",
  EDIT: "Modifier",
  EDIT_FOLDER: "Modifier le dossier",
  DELETE_THIS_ITEM: "Supprimer ces éléments?",
  UPLOAD: "Télécharger",
  SHARE: "Restrictions",
  FOLDER: "Dossier",
  FILE: "Fichier",
  FILE_HISTORY: "Historique du fichier",
  HISTORY: "Historique",
  FOLDER_EXISTS_WITH_SAME_NAME: "Un dossier existe déjà avec ce nom",
  FILE_EXISTS_WITH_SAME_NAME: "Un fichier existe déjà avec ce nom",
  FILE_EXISTS_WITH_SAME_NAME_X: "Un fichier existe déjà avec ce nom {filename}",
};
