function save(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function get(key) {
  const value = localStorage.getItem(key);
  try {
    return value ? JSON.parse(value) : {};
  } catch (e) {
    return {};
  }
}

export default {
  get,
  save,
};
