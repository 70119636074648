import service from "@/store/services/organization-options-service";

function formatOptionsMapKeyValue(optionsList) {
  const formattedOptions = {};
  for (const option of optionsList) {
    formattedOptions[option.name] = option.value;
  }
  return formattedOptions;
}

function formatOptionsMapKeyOption(optionsList) {
  const formattedOptions = {};
  for (const option of optionsList) {
    formattedOptions[option.name] = option;
  }
  return formattedOptions;
}

export const state = () => ({
  optionsMapKeyOption: {},
  organizationConfig: {},
});

export const mutations = {
  SET_ORGANIZATION_CONFIG: (state, organizationConfig) => {
    state.organizationConfig = organizationConfig;
  },
  SET_OPTIONS_MAP_KEY_OPTION: (state, optionsMapKeyOption) => {
    state.optionsMapKeyOption = optionsMapKeyOption;
  },
};

export const actions = {
  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then(({ list, meta }) => {
      commit("SET_ORGANIZATION_CONFIG", formatOptionsMapKeyValue(list));
      commit("SET_OPTIONS_MAP_KEY_OPTION", formatOptionsMapKeyOption(list));
    });
  },

  getOrganizationConfig({ commit, dispatch }, params) {
    return service
      .getOrganizationConfig(params, this.$axios)
      .then(({ list, meta }) => {
        commit("SET_ORGANIZATION_CONFIG", formatOptionsMapKeyValue(list));
        commit("SET_OPTIONS_MAP_KEY_OPTION", formatOptionsMapKeyOption(list));
      });
  },

  updateOptionByKey(
    { commit, dispatch, state },
    { key, value, organizationId }
  ) {
    const option = state.optionsMapKeyOption[key];
    option.value = value;
    option.organization = {
      type: "organizations",
      id: organizationId,
    };
    return service.update(option, this.$axios).then((option) => {
      dispatch("getOrganizationConfig", organizationId);
    });
  },
};

const getters = {
  organizationConfig: (state) => state.organizationConfig,
};

const organizationConfig = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default organizationConfig;
