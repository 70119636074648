export default {
  ORGANIZATIONS_LIST: "Liste des campings",
  ADD_ORGANIZATION: "Ajouter un camping",
  ORGANIZATION_ADDED: "Camping ajouté",
  OWNER: "Propriétaire",
  EDIT_ORGANIZATION: "Modifier un camping",
  ORGANIZATION_UPDATED: "Camping mis à jour",
  SELECT_A_PACKAGE: "Choisir un forfait",
  ADD_SUBSCRIPTION: "Ajouter un abonnement",
  SUBSCRIPTION_INVOICE_ADDED: "Facture abonnement ajoutée",
  AUTHORIZATIONS: "Autorisations",
  DELETE_THIS_ORGANIZATION: "Supprimer ce camping ?",
  ORGANIZATION_DELETED: "Camping supprimé",
  VIEW_ORGANIZATION: "Détails du camping",
  NO_DATA:
    "Vous n’avez ajouté aucun camping pour le moment, cliquer sur le bouton pour ajouter.",
};
