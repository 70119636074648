export default {
  IMPORTS_LIST: "Liste des imports",
  IMPORTS_PREVIEW: "Voir un aperçu",
  ADD_IMPORT: "Ajouter un import",
  IMPORT_ADDED: "Imports ajoutée",
  EDIT_IMPORT: "Modifier un import",
  IMPORT_UPDATED: "Imports mise à jour",
  DELETE_THIS_IMPORT: "Supprimer ce import ?",
  IMPORT_DELETED: "Imports supprimé",
  IMPORT_MODEL: "Modèle",
  CHOOSE_MODEL: "Choisissez un modèle d'import",
  IMPORT_NAME: "Nom",
  IMPORT_FILE_SIZE: "Taille du fichier",
  COLUMN_ROW_COUNT: "Lignes d'import",
  SHOW_ROWS: "Données de l'import",
  COUNT_CSV_ROWS: "Nombre de lignes",
  IMPORT_PREVIEW_DATA: "Appercu des donnees à importer",
  CSV_PREVIEW_DATA: "Appercu des donnees du fichier",
  FILE: "Fichier",
  RUN_DRY_IMPORT: "Tester l'import",
  RUN_IMPORT: "Lancer l'import",
  RESULTS_ON_X_LINES: "Resultats pour un total de {linesCount} lignes",
  SUCCESSES: "Succes",
  FAILURES: "Echecs",
  X_ROW: "Ligne {line}",
  LINE: "Ligne",
  DATA: "Donnee",
  ERROR: "Erreur",
  MODEL_ID: "ID",
  RESULTS: "Resultats",
  IMPORTED_ITEMS: "Objets importés",
  ITEM: "Objet",
  NO_DATA:
    "Vous n’avez ajouté aucun import pour le moment, cliquer sur le bouton pour ajouter.",
};
