export default {
  RESELLER_PAYMENTS_LIST: "Liste des paiements",
  ADD_RESELLER_PAYMENT: "Ajouter un paiement",
  RESELLER_PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_RESELLER_PAYMENT: "Modifier le paiement",
  RESELLER_PAYMENT_SOURCE_MANUAL: "Manuel",
  RESELLER_PAYMENT_SOURCE_PAYPAL: "Paypal",
  RESELLER_PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  VIEW_INVOICE: "Voir la facture",
  VIEW_RESELLER_PAYMENT: "Facture",
  VIEW: "Paiement",
  NO_DATA:
    "Vous n’avez ajouté aucun paiement pour le moment, cliquer sur le bouton pour ajouter.",
};
