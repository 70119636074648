<template>
  <Header class="header">
    <div class="header-inner">
      <div class="organization">
        <svg
          width="78"
          height="80"
          viewBox="0 0 78 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M52.942 51.617C55.1225 47.2173 55.6908 42.89 54.2389 38.3999C53.1461 35.0181 51.1981 32.2305 48.3382 30.1121C42.466 25.7615 34.4546 25.6659 28.541 29.9183C25.2108 32.3106 23.0717 35.54 22.1856 39.5651C21.3201 43.5075 21.4751 44.8974 23.3998 51.0719C23.0175 51.0848 22.865 50.7593 22.679 50.5397C17.9797 45.0524 15.4659 38.7926 16.3727 31.4942C17.1685 25.0975 20.279 19.9486 25.4873 16.1302C27.9028 14.3606 30.0213 12.3583 31.5559 9.76194C32.9406 7.41613 33.7958 4.90499 34.1032 2.20524C34.1161 2.09674 34.111 1.98565 34.1265 1.87714C34.3848 0.112617 35.475 -0.47125 37.0458 0.396801C40.8229 2.48684 43.8042 5.39844 45.9123 9.17808C47.9585 12.8466 48.9066 16.7864 48.8317 20.982C48.8239 21.4445 48.6612 21.9379 49.0332 22.5373C49.8367 21.7157 50.4464 20.8374 51.0147 19.9306C51.2472 19.5611 51.4307 19.1607 51.6683 18.7938C52.5054 17.4969 53.5181 17.3626 54.6368 18.445C56.6855 20.424 58.1426 22.8137 59.008 25.498C61.0929 31.9799 60.9508 38.4206 58.3002 44.7398C57.2254 47.2974 55.2 49.9068 52.942 51.617Z"
            fill="#576654"
          />
          <path
            d="M51.6236 66.591C54.9589 67.968 58.2916 69.3424 61.6191 70.722C62.0221 70.8899 62.4355 71.0475 62.8152 71.2594C63.7996 71.8045 64.3292 72.6312 64.3653 73.7835C64.4144 75.4472 63.8409 76.9069 62.9057 78.2245C61.6863 79.9373 60.2834 80.33 58.3148 79.5317C54.9692 78.178 51.6391 76.788 48.3012 75.4085C46.0768 74.4887 43.8318 73.6181 41.6384 72.6286C39.8196 71.8071 38.1068 71.8278 36.2622 72.6106C30.8782 74.8995 25.4141 77.0102 20.0585 79.3612C16.979 80.7124 15.2817 79.1933 14.3025 77.1446C14.1604 76.8501 14.0209 76.5504 13.9098 76.2429C13.0056 73.7292 13.2123 71.8278 16.3125 70.6549C19.6323 69.3993 22.89 67.968 26.1633 66.6194C26.0264 65.9994 25.4968 66.0046 25.1273 65.847C23.0657 64.966 20.9783 64.147 18.914 63.2687C17.0203 62.4626 16.4261 61.4628 16.5657 59.427C16.669 57.9028 17.2503 56.5542 18.1933 55.3761C19.1698 54.1567 20.4047 53.7743 21.8515 54.3169C23.7452 55.0273 25.5975 55.8489 27.468 56.6213C28.1578 56.9055 28.584 57.4816 29.031 58.0371C31.1598 60.6826 33.286 63.3255 35.4148 65.971C35.655 66.2707 35.8876 66.5729 36.1356 66.8675C37.8898 68.9523 39.9772 68.9317 41.6126 66.7693C43.5941 64.1496 45.5782 61.53 47.4745 58.8483C48.2418 57.7658 49.177 57.0528 50.3913 56.5903C52.1274 55.9264 53.8325 55.1772 55.5582 54.4926C57.6095 53.6788 59.0098 54.1619 60.1439 56.0607C60.7665 57.1045 61.2238 58.2154 61.322 59.4399C61.4718 61.3362 60.8311 62.4445 59.0821 63.1989C57.0231 64.0876 54.9356 64.9066 52.874 65.785C52.44 65.971 51.8819 65.9917 51.6236 66.591Z"
            fill="#576654"
          />
          <path
            d="M29.7266 43.4607C29.4992 38.8285 33.6302 34.7854 38.3115 34.7466C42.1273 34.713 46.9248 37.6091 47.0669 43.3445C47.1858 48.1446 43.1504 52.2472 38.3502 52.1542C32.6795 52.0431 29.5173 47.3489 29.7266 43.4607Z"
            fill="#576654"
          />
        </svg>
        <organization-selector
          :organization="globalOrganization?.id"
          :filterable="true"
          :showAll="false"
          :allowNone="true"
          @organizationChanged="selectOrganization"
        />
      </div>
      <div class="lang">
        <base-dropdown
          title-classes="btn btn-sm btn-neutral"
          menu-on-right
          id="lang-switcher"
          :has-toggle="false"
          menuClasses="lang-switcher"
        >
          <template slot="title">
            <span class="text">{{ $i18n.locale }}</span>
            <span class="icon">
              <i class="fal fa-chevron-down"></i>
            </span>
          </template>
          <li v-for="lang in langs" :key="lang.id">
            <a
              class="dropdown-item"
              v-bind:class="{ active: lang.slug === $i18n.locale }"
              @click="selectLang(lang.slug)"
            >
              <img src="/img/canada-flag.svg" alt="flag" />
              <span>{{ lang.text }}</span>
              <span class="check"><i class="fal fa-check"></i></span>
            </a>
          </li>
        </base-dropdown>
      </div>
      <div class="notifications">
        <button>
          <img src="/img/kw-notifications.svg" alt="icon" />
        </button>
      </div>
      <div class="user">
        <base-dropdown
          title-classes="btn btn-sm btn-neutral"
          menu-on-right
          id="user-switcher"
          :has-toggle="false"
          menuClasses="user-switcher"
        >
          <template slot="title">
            <div class="user-toggle">
              <div class="image">
                <img
                  v-if="me?.profile_image"
                  :src="me?.profile_image"
                  alt="profile-picture"
                />
                <span>
                  {{ `${me?.firstname.charAt(0)}${me?.lastname.charAt(0)}` }}
                </span>
              </div>
              <ul>
                <li>{{ `${me?.firstname + " " + me?.lastname}` }}</li>
                <li>{{ me?.roles[0]?.name }}</li>
              </ul>
              <i class="fal fa-chevron-down"></i>
            </div>
          </template>
          <!-- <li>
            <a href="#" class="settings">
              <img src="/img/kw-settings.svg" alt="icon" />
              <span>Paramètres</span>
            </a>
          </li> -->
          <li>
            <router-link
              :to="{ name: 'View Organization' }"
              class="settings"
              v-if="
                $currentUserCan($permissions.PERM_VIEW_OWN_ORGANIZATIONS) &&
                !!me.organization
              "
            >
              <i class="fas fa-building text-black-50"></i>
              <span> {{ $t("COMMON.MY_ORGANIZATION") }} </span>
            </router-link>
          </li>
          <li>
            <button class="logout" @click="logout">
              <img src="/img/kw-logout.svg" alt="icon" />
              <span>{{ $t("COMMON.LOGOUT") }}</span>
            </button>
          </li>
        </base-dropdown>
      </div>
    </div>
  </Header>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import swal from "sweetalert2";

export default {
  components: {
    OrganizationSelector,
  },

  mixins: [],

  data() {
    return {
      langs: [
        {
          id: 1,
          text: "Français",
          slug: "fr",
        },
        {
          id: 2,
          text: "Anglais",
          slug: "en",
        },
      ],
    };
  },

  methods: {
    async selectOrganization(organizationId, organization) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("organizations/setOrganization", organization);
      const currentRoute = this.$router.currentRoute;
      try {
        await this.$router.push("/");
      } catch (e) {
        console.log(e);
      }
      try {
        await this.$router.push(currentRoute);
      } catch (e) {
        console.log(e);
      }
      swal.close();
    },

    selectLang(lang) {
      loadLanguageAsync(lang);
    },

    menuHoverObserver() {
      const firstMenuLinks = document.querySelectorAll(
        ".elite-content_sidebar_level1_menu_wrapper > ul > li > a"
      );
      for (const menuLink of firstMenuLinks) {
        menuLink.removeEventListener("click", this.menuLinkClickHandler);
      }
      for (const menuLink of firstMenuLinks) {
        menuLink.addEventListener("click", this.menuLinkClickHandler);
      }
    },

    logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  watch: {
    me: {
      handler: debounce(function (val) {
        this.menuHoverObserver();
      }),
      deep: true,
    },
  },
};
</script>
