export default {
    TODAY: "Aujourd'hui",
    PLUS_31_DAYS: "31 Jours",
    JANUARY: "Janvier",
    FEBRUARY: "Février",
    MARCH: "Mars",
    APRIL: "Avril",
    MAY: "Mai",
    JUNE: "Juin",
    JULY: "Juillet",
    AUGUST: "Août",
    SEPTEMBER: "Septembre",
    OCTOBER: "Octobre",
    NOVEMBER: "Novembre",
    DECEMBER: "Décembre"
};