import store from "..";
import optionsService from "./options-service";

function get() {
  let me = store.getters["profile/me"];
  if (me.organization) {
    const params = {
      sort: "name",
      filter: {
        organization: me.organization.id,
      },
      page: {
        number: 1,
        size: 999999,
      },
    };
    return optionsService.list(params);
  } else {
    return new Promise((resolve) => resolve());
  }
}

function getOrganizationConfig(id) {
  const params = {
    sort: "name",
    filter: {
      organization: id,
    },
    page: {
      number: 1,
      size: 999999,
    },
  };
  return optionsService.list(params);
}

export default {
  get,
  getOrganizationConfig,
  update: optionsService.update,
};
