export default {
  EQUIPMENT_CATEGORY_LIST: "Liste des catégories",
  ADD_EQUIPMENT_CATEGORY: "Ajouter une catégorie",
  EQUIPMENT_CATEGORY_ADDED: "Catégorie ajouté",
  EDIT_EQUIPMENT_CATEGORY: "Modifier la catégorie",
  EQUIPMENT_CATEGORY_UPDATED: "Catégorie mise à jour",
  DELETE_THIS_EQUIPMENT_CATEGORY:
    "Voulez-vous supprimer la catégorie sélectionné ?",
  EQUIPMENT_CATEGORY_DELETED: "Catégorie supprimée",
  EQUIPMENT_CATEGORY_NAME: "Nom de la catégorie",
  VIEW_EQUIPMENT_CATEGORY: "Catégorie de service",
  DETAILS_VIEW_EQUIPMENT_CATEGORY: "Détails de la catégorie de service",
  SAME_EQUIPMENT_CATEGORIES:
    'Un ou plusieurs catégories d\'equipement existent déja avec le nom {name}. S\'agit-il de la même catégory ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  NO_DATA:
    "Vous n’avez ajouté aucune catégorie de service pour le moment, cliquer sur le bouton pour ajouter.",
  CATEGORY_NAME: "Nom de la catégorie",
};
