import service from "@/store/services/organizations-service";
import localStorage from "@/store/services/localstorage-service";
import Jsona from "jsona";

const jsona = new Jsona();

const KEY_GLOBAL_ORGANIZATION = "GLOBAL_ORGANIZATION";

export const state = () => ({
  list: {},
  organization: {},
  meta: {},
  url: null,
  globalOrganization: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, organization) => {
    state.organization = organization;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_GLOBAL_ORGANIZATION: (state, organization) => {
    state.globalOrganization = organization;
  },
};

export const actions = {
  initState({ commit, dispatch, getters, rootGetters }) {
    const me = rootGetters["profile/me"];
    let organization = localStorage.get(KEY_GLOBAL_ORGANIZATION);
    if (me.organization) {
      organization = me.organization;
    }
    if (organization) {
      dispatch("setOrganization", organization);
    }
  },

  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((organization) => {
      commit("SET_RESOURCE", organization);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((organization) => {
      commit("SET_RESOURCE", organization);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((organization) => {
      commit("SET_RESOURCE", organization);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  generateSubscriptionInvoice({ commit, dispatch }, { organizationId, data }) {
    return service.generateSubscriptionInvoice(
      organizationId,
      data,
      this.$axios
    );
  },

  setOrganization({ commit, dispatch }, organization) {
    if (organization) {
      if (organization.id) {
        dispatch("organizationOptions/getOrganizationConfig", organization.id, {
          root: true,
        });
      }
    }
    commit("SET_GLOBAL_ORGANIZATION", organization);
    localStorage.save(KEY_GLOBAL_ORGANIZATION, organization);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  organization: (state) => state.organization,
  url: (state) => state.url,
  globalOrganization: (state) => state.globalOrganization,
  dropdown: (state) => {
    return state.list.map((organization) => ({
      id: organization.id,
      name: `${organization.name}`,
    }));
  },
};

const organizations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default organizations;
