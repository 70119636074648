<template>
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_LOCATIONS)">
      <router-link
        :to="{ name: 'List Locations' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.LOCATIONS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS)">
      <router-link
        :to="{ name: 'List Organizations' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.ORGANIZATIONS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLERS)">
      <router-link
        :to="{ name: 'List Resellers' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.RESELLERS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_USERS)">
      <router-link
        :to="{ name: 'List Users' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.USERS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ROLES)">
      <router-link
        :to="{ name: 'List Roles' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.ROLES") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_IMPORTS)">
      <router-link
        :to="{ name: 'List Imports' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.IMPORTS") }}
          </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},

  mixins: [],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
