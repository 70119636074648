import permissions from "../constants/permissions";
import BookingModuleLayout from "@/views/Pages/BookingModule/BookingModuleLayout.vue";
import BookingModuleMenu from "@/views/Pages/BookingModule/BookingModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListBookingPage = () =>
  import("@/views/Pages/BookingModule/BookingManagement/ListBookingPage");

const NewBookingPage = () =>
  import("@/views/Pages/BookingModule/BookingManagement/NewBookingPage");

const PendingBookingPage = () =>
  import("@/views/Pages/BookingModule/BookingManagement/PendingBookingPage");

const BookingPage = () => import("@/views/Pages/BookingModule/BookingPage.vue");

let bookingManagementPages = {
  path: "bookings",
  components: {
    default: BookingModuleLayout,
    SidebarSecondLevelMenu: BookingModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Bookings",
      components: { default: ListBookingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_BOOKINGS,
        hasLevel2: true,
      },
    },
    {
      path: "new",
      name: "New Booking",
      components: { default: NewBookingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_BOOKINGS,
        hasLevel2: true,
      },
    },
    {
      path: "pending",
      name: "Pending Bookings",
      components: { default: PendingBookingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_BOOKINGS,
        hasLevel2: true,
      },
    },
  ],
};

const BookingModuleRoutes = [
  {
    path: "",
    components: {
      default: BookingModuleLayout,
      SidebarSecondLevelMenu: BookingModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Booking Page",
        components: { default: BookingPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  bookingManagementPages,
];

export default BookingModuleRoutes;
