<template>
  <div class="elite-content_sidebar_level2">
    <div class="elite-content_sidebar_level2_inner">
      <h2>{{ $t("APPS.AUCTION_MODULE") }}</h2>
      <ul class="sub-menu-items">
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_AUCTIONS)">
          <router-link
            :to="{ name: 'List Auctions' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.AUCTIONS") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PROPERTIES)">
          <router-link
            :to="{ name: 'List Properties' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PROPERTIES") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
