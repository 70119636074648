import permissions from "../constants/permissions";
import CalendarModuleLayout from "@/views/Pages/CalendarModule/CalendarModuleLayout.vue";
import CalendarModuleMenu from "@/views/Pages/CalendarModule/CalendarModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const CalendarPage = () =>
  import(
    "@/views/Pages/CalendarModule/CalendarManagement/CalendarPage.vue"
  );

// let customManagementPages = {
//     path: "calendars",
//     components: {
//         default: CalendarModuleLayout,
//         SidebarSecondLevelMenu: CalendarModuleMenu,
//     },
//     children: [
//         {
//             path: "",
//             name: "List Calendars",
//             components: { default: CalendarPage },
//             meta: {
//                 middleware: authenticated,
//                 // middleware: authorized,
//                 // permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
//                 hasLevel2: true,
//             },
//         },
//     ],
// };

const calendarModuleRoutes = [
  {
    path: "",
    components: {
      default: CalendarModuleLayout,
      SidebarSecondLevelMenu: CalendarModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Calendar Page",
        components: { default: CalendarPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  // customManagementPages
];

export default calendarModuleRoutes;
