import store from "../store";

const formatString = {
  install: (Vue) => {
    Vue.prototype.$truncate = (value, size, fill = '...') => {
      if (value.length > size) {
        return value.slice(0, size) + fill;
      } else {
        return value;
      }
    };
  },
};

export default formatString;
