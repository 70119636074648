import permissions from "../constants/permissions";
import CustomerModuleLayout from "@/views/Pages/CustomerModule/CustomerModuleLayout.vue";
import CustomerModuleMenu from "@/views/Pages/CustomerModule/CustomerModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const CustomerPage = () =>
    import("@/views/Pages/CustomerModule/CustomerManagement/ListCustomerPage.vue");

let customManagementPages = {
    path: "customers",
    components: {
        default: CustomerModuleLayout,
        SidebarSecondLevelMenu: CustomerModuleMenu,
    },
    children: [
        {
            path: "",
            name: "List Customers",
            components: { default: CustomerPage },
            meta: {
                middleware: authorized,
                permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
                hasLevel2: true,
            },
        },
    ],
};

const customerModuleRoutes = [
    {
        path: "",
        components: {
            default: CustomerModuleLayout,
            SidebarSecondLevelMenu: CustomerModuleMenu,
        },
        children: [
            {
                path: "",
                name: "Customer Page",
                components: { default: CustomerPage },
                meta: {
                    middleware: authenticated,
                    hasLevel2: true,
                },
            },
        ],
    },
    customManagementPages
];

export default customerModuleRoutes;
