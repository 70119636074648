import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/properties`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/properties/${id}?include=organization,auction,owners,allowedLocations,createdBy,updatedBy,paymentReceivedBy`,
      options
    )
    .then((response) => {
      let property = jsona.deserialize(response.data);
      delete property.links;
      return property;
    });
}

function add(property) {
  const payload = jsona.serialize({
    stuff: property,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/properties?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(property) {
  const payload = jsona.serialize({
    stuff: property,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/properties/${property.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/properties/${id}`, options);
}

function activate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/properties/${id}/activate`, options)
    .then((response) => {
      let property = jsona.deserialize(response.data);
      delete property.links;
      return property;
    });
}

function deactivate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/properties/${id}/deactivate`, options)
    .then((response) => {
      let property = jsona.deserialize(response.data);
      delete property.links;
      return property;
    });
}

function exportExcel(params) {
  const options = {
    params: params,
    responseType: "blob",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios.get(`${url}/properties/exports`, options);
}

function printPdfLetters(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.post(`${url}/properties/print-letters`, data, options);
}

function exportThumbnails() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.get(`${url}/properties/export-thumbnails`, options);
}

function printLettersForSelection(data) {
  const options = {
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.post(
    `${url}/properties/print-letters-for-selection`,
    data,
    options
  );
}

function exportThumbnailsForSelection(selectedProperties, details) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  const data = {
    selectedProperties: selectedProperties,
    details: details,
  };
  return axios.post(
    `${url}/properties/export-thumbnails-for-selection`,
    data,
    options
  );
}

function print(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };
  return axios.get(
    `${url}/properties/${id}/print-transaction-receipt`,
    options
  );
}

function createTransaction(property) {
  const payload = jsona.serialize({
    stuff: property,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/properties/${property.id}/transactions`, property, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroyTransaction(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/properties/${id}/transactions`, options);
}

export default {
  list,
  get,
  add,
  print,
  update,
  destroy,
  activate,
  deactivate,
  exportExcel,
  printPdfLetters,
  exportThumbnails,
  createTransaction,
  destroyTransaction,
  printLettersForSelection,
  exportThumbnailsForSelection,
};
