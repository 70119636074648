export default {
  SALES_INVOICES_LIST: "Liste des facturations",
  SALES_INVOICES: "Factures",
  SALES_INVOICES_LIST_SHORT: "Liste des factures",
  SALES_INVOICES_CODE_LABEL: "Nº de facture",
  SALES_INVOICES_FOLIOS_LIST: "Liste des folios",
  FOLIOS_CODE_LABEL: "Nº de folio",
  SALES_INVOICES_TRANSFERTS_LIST: "Liste des transferts",
  ADD_SALES_INVOICE: "Ajouter une facture",
  ADD_SALES_INVOICE_ITEM: "Ajouter une ligne",
  SALES_INVOICE_ADDED: "Facture ajoutée",
  ASSOCIATED_BOOKING: "Numéros de réservation associés",
  OWNER: "Propriétaire",
  EDIT_SALES_INVOICE: "Modifier une facture",
  SALES_INVOICE_UPDATED: "Facture mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_SALES_INVOICE_ITEM: "Supprimer la ligne",
  UPDATE_SALES_INVOICE_ITEM: "Modifier la ligne",
  SALES_INVOICE_ITEM_DELETED: "Ligne supprimée",
  SALES_INVOICE_ITEM_ADDED: "Ligne ajoutée",
  SALES_INVOICE_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  SALES_INVOICE_SENT: "Facture envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir le camping",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_SALES_INVOICE: "Supprimer cette facture ?",
  SALES_INVOICE_DELETED: "Facture supprimée",
  CANCEL_SALES_INVOICE_QUESTION: "Annuler la facture ?",
  SALES_INVOICE_CANCELED: "Facture annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  SALES_INVOICE_VALIDATED: "Facture validée",
  INVOICE_STATUS_VALIDATED: "Validée",
  INVOICE_STATUS_CANCELLED: "Annulée",
  INVOICE_STATUS_PAID: "Payée",
  INVOICE_STATUS_DRAFT: "Brouiilon",
  VIEW_ORDER: "Voir la commande",
  VIEW_SALES_INVOICE: "Détails facturation",
  CLOSE_FOLIO: "Fermer le folio",
  CUSTOMER_NAME: "Nom du client",
  MODIFY: "Modifier",
  CHANGE: "Changer",
};
