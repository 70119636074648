<template>
  <div class="light-loading">
    <div class="row" v-loading="true" id="loading"></div>
    <p>{{ $t("COMMON.ONGOING_LOADING") }}...</p>
  </div>
</template>
<script>
import Vue from "vue";
import { Loading } from "element-ui";

Vue.use(Loading.directive);
export default {};
</script>
<style>
#loading {
  min-height: 42px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.el-loading-mask {
  background: transparent !important;
}
</style>
