export default {
  EQUIPMENT_LIST: "Liste des services",
  ADD_EQUIPMENT: "Ajouter un service",
  EQUIPMENT_ADDED: "Service ajouté",
  EDIT_EQUIPMENT: "Modifier le service",
  EQUIPMENT_UPDATED: "Service mis à jour",
  DELETE_THIS_EQUIPMENT: "Voulez-vous supprimer le service sélectionné ?",
  DELETE_THIS_EQUIPMENT_SUCCESS:
    "La suppression du service a été effectuée avec succès",
  EQUIPMENT_DELETED: "Service supprimé",
  EQUIPMENT_NAME: "Nom du service",
  EQUIPMENT_CATEGORY_NAME: "Catégorie de service",
  SAME_EQUIPMENTS:
    'Un ou plusieurs services existent déja avec le nom {name}. S\'agit-il du même service ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  VIEW_EQUIPMENT: "Service",
  DETAILS_VIEW_EQUIPMENT: "Détail du service",
  NO_DATA:
    "Vous n’avez ajouté aucun service pour le moment, cliquer sur le bouton pour ajouter.",
  EQUIPMENT_INFORMATIONS: "Informations sur le service",
};
