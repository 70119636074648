import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();
const defaultOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
}

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/credit-cards`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  return axios
    .get(
      `${url}/credit-cards/${id}`,
        defaultOptions
    )
    .then((response) => {
      let creditCard = jsona.deserialize(response.data);
      delete creditCard.links;
      return creditCard;
    });
}

function add(customerId, creditCard) {
  return axios
    .post(`${url}/customers/${customerId}/add-credit-card`, creditCard, defaultOptions)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(creditCard) {
  const payload = jsona.serialize({
    stuff: creditCard,
    includeNames: [],
  });

  return axios
    .patch(`${url}/credit-cards/${creditCard.id}`, payload, defaultOptions)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  return axios.delete(`${url}/credit-cards/${id}`, defaultOptions);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
