export default {
  ROLES_LIST: "Liste des rôles",
  ADD_ROLE: "Ajouter un rôle",
  ROLE_ADDED: "Rôle ajouté",
  ROLES_UPDATED: "Rôle modifié",
  AUTHORIZATIONS: "Autorisations",
  EDIT_ROLE: "Modifier le rôle",
  DELETE_THIS_ROLE: "Supprimer ce rôle ?",
  ROLE_DELETED: "Rôle supprimé",
  VIEW_ROLE: "Détails du rôle",
  COPY_PERMISSIONS: "Copier les permissions",
  NO_DATA:
    "Vous n’avez ajouté aucun rôle pour le moment, cliquer sur le bouton pour ajouter.",
};
