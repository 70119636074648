<template>
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'list' } }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.NEW_BOOKING") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'calendar' } }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("COMMON.CALENDAR") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'map' } }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("COMMON.MAP") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_BOOKINGS)">
      <router-link
        :to="{ name: 'List Bookings' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.BOOKING_LIST") }}
          </span>
        </a>
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'Pending Bookings' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}
            <span v-if="pending_count" style="margin-left: 2px">
              ({{ pending_count }})
            </span>
          </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";
import { mapGetters } from "vuex";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {
      pending_count: 0,
    };
  },

  created() {
    this.getPendingBookings();
  },

  methods: {
    async getPendingBookings() {
      try {
        const params = {
          filter: {
            status: ORDER_STATUS_DRAFT,
            updated_by: this.me.id,
          },
          page: {
            number: 1,
            size: 1,
          },
        };

        await this.$store.dispatch("salesOrders/list", params);
        this.pending_count = await this.$store.getters["salesOrders/listTotal"];
      } catch (error) {
        this.pending_count = 0;
      }
    },
  },

  async mounted() {},

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},
};
</script>
