<template>
  <!-- <h2>{{ $t("APPS.RESELLER_MODULE") }}</h2> -->
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)">
      <router-link
        :to="{ name: 'List ResellerInvoices' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.RESELLER_INVOICES") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)">
      <router-link
        :to="{ name: 'List ResellerPayments' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.RESELLER_PAYMENTS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS)">
      <router-link
        :to="{ name: 'List Subscriptions' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.SUBSCRIPTIONS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)">
      <router-link
        :to="{ name: 'List ResellerProducts' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.RESELLER_PRODUCTS") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)">
      <router-link
        :to="{ name: 'List ResellerServices' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.RESELLER_SERVICES") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PACKAGES)">
      <router-link
        :to="{ name: 'List Packages' }"
        v-slot="{ href, navigate, isActive }"
        custom
      >
        <a :href="href" @click="navigate" :class="{ 'active-link': isActive }">
          <span class="text">
            {{ $t("COMMON.PACKAGES") }}
          </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
