<template>
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'List PricingRules' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("PRICING_RULES.PRICE") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRICING_RULES)">
      <router-link
        :to="{ name: 'View Calendar' }"
        v-slot="{ href, navigate }"
        custom
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("PRICING_RULES.CALENDAR") }}
          </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
