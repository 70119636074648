export default {
  LOCATIONS_LIST: "Liste des emplacements",
  ADD_LOCATION: "Ajouter un emplacement",
  LOCATION_ADDED: "Emplacement ajouté",
  LOCATIONS_UPDATED: "Emplacement modifié",
  EDIT_LOCATION: "Modifier cet emplacement",
  DELETE_THIS_LOCATION: "Supprimer cet emplacement ?",
  LOCATION_DELETED: "Emplacement supprimé",
  VIEW_LOCATION: "Détails de l'emplacement",
  IS_MUNICIPAL: "Est une municipalité",
  NO_DATA:
    "Vous n’avez ajouté aucun emplacement pour le moment, cliquer sur le bouton pour ajouter.",
};
