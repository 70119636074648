const pictureResize = {
  install: (Vue) => {
    Vue.prototype.$pictureResize = (url, width = null, height = null) => {
      if (!url) {
        return null;
      }
      const baseURL = process.env.VUE_APP_AWS_RESIZE_BASE_URL;
      if (!baseURL) {
        return url;
      }
      const path = url.replace(/^https?:\/\/[^/]+\//, "");
      const queryParams = {
        path: path,
        width: width,
        height: height
      };
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      return `${baseURL}?${queryString}`;
    };
  },
};

export default pictureResize;
